import React, { useState } from "react";
import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import { TableCell, TableRow, Box } from "@material-ui/core";
import EditDepartmentModal from "./EditDepartmentModal";
import toast from "react-hot-toast";
import { removeDepartment } from "../../../api/department/Department";
import ViewAndEditDepartmentModal from "./ViewAndEditDepartmentModal";
import PermissionGuard from "../../PermissionGuard";
import DeleteModal from "../../DeleteModal";

const SingleDepartment = ({
  getAllDepartmentsUnderAdmin,
  singleDepartment,
  index,
}) => {
  const [showEditDepartmentModal, setShowEditDepartmentModal] = useState(false);
  const [showViewAndEditDepartmentModal, setShowViewAndEditDepartmentModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteDepartment = async (departmentId) => {
    try {
      const res = await removeDepartment(departmentId);
      if (res.data.success) {
        toast.success("Department deleted");
        getAllDepartmentsUnderAdmin();
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={() => deleteDepartment(singleDepartment.id)}
        warningMessage="Are you sure you want to delete this department?"
      />
      {showViewAndEditDepartmentModal && (
        <ViewAndEditDepartmentModal
          showViewAndEditDepartmentModal={showViewAndEditDepartmentModal}
          setShowViewAndEditDepartmentModal={setShowViewAndEditDepartmentModal}
          getAllDepartmentsUnderAdmin={getAllDepartmentsUnderAdmin}
          singleDepartment={singleDepartment}
        />
      )}
      {showEditDepartmentModal && (
        <EditDepartmentModal
          showEditDepartmentModal={showEditDepartmentModal}
          setShowEditDepartmentModal={setShowEditDepartmentModal}
          getAllDepartmentsUnderAdmin={getAllDepartmentsUnderAdmin}
          singleDepartment={singleDepartment}
        />
      )}
      <TableRow hover>
        <TableCell
          sx={{ cursor: "pointer" }}
          onClick={() => setShowViewAndEditDepartmentModal(true)}
        >{`${singleDepartment.department}`}</TableCell>
        <TableCell>{singleDepartment.total_members || 0}</TableCell>
        <TableCell>
          {singleDepartment?.get_department_head
            ? `${singleDepartment?.get_department_head?.first_name} ${singleDepartment?.get_department_head?.last_name}`
            : "-"}
        </TableCell>
        <TableCell>
          {singleDepartment?.get_department_head?.email || "-"}
        </TableCell>
        <TableCell align="center">
          <PermissionGuard
            permissionCheck="DEPARTMENT_UPDATE"
            returnEmpty={true}
          >
            <PencilAltIcon
              title="Edit"
              onClick={() => setShowEditDepartmentModal(true)}
              className="action-icon"
            />
          </PermissionGuard>
          <PermissionGuard
            permissionCheck="DEPARTMENT_DELETE"
            returnEmpty={true}
          >
            <TrashIcon
              title="delete"
              className="action-icon"
              onClick={() => setShowDeleteModal(true)}
            />
          </PermissionGuard>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleDepartment;

import { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { saveReportFilter } from "../../../../api/reports/CustomReport";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const SaveFilterModal = ({
  saveFilterModalOpen,
  setSaveFilterModalOpen,
  filterData,
  view,
  getTaskSavedReports,
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  if (!filterData.columnGroupState || !filterData.columnState) {
    toast.message("Corrupted Data format");
    setSaveFilterModalOpen(false);
    return;
  }

  return (
    <>
      <Dialog
        open={saveFilterModalOpen}
        onClose={() => setSaveFilterModalOpen(false)}
        maxWidth={"md"}
      >
        <Formik
          initialValues={{
            title: "",
            level: 3,
            description: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required("Filter title is required"),
            level: Yup.number().max(3).required("Filter level is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await saveReportFilter({
                title: values.title,
                description: values.description,
                view: view,
                level: values.level,
                filter_data: JSON.stringify(filterData),
              });

              if (res.data.success) {
                toast.success(res.data.data.message);
                getTaskSavedReports();
                setSaveFilterModalOpen(false);
                setSubmitting(false);
                setStatus({ success: true });
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Save Filter</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      required
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      margin="normal"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      error={Boolean(touched.level && errors.level)}
                    >
                      <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                        Level
                      </InputLabel>
                      <Select
                        labelId="select-status"
                        label="Level"
                        name="level"
                        value={values.level}
                        fullWidth
                        placeholder="Status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <MenuItem value={3}>Personal</MenuItem>
                        <MenuItem value={2}>Company</MenuItem>
                        {user.role === 1 && (
                          <MenuItem value={1}>Global</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>
                        {touched.type && errors.type}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setSaveFilterModalOpen(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default SaveFilterModal;

import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import useMounted from "../../../hooks/useMounted";
import BookRoomIntakeForm from "./BookRoomIntakeForm";

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const CreateNewRoom = ({showModal, setShowModal, createNewRoom}) => {
  const classes = useStyles();
  const mounted = useMounted();
  return (
    <>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        minWidth={"md"}
        fullWidth={true}
      >
        <BookRoomIntakeForm />
      </Dialog>
    </>
  )
}

export default CreateNewRoom
import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  InputLabel,
  Radio,
  Autocomplete,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import axios from "axios";
import DatePicker from "@material-ui/lab/DatePicker";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";
import projectPriority from "../../../@const/projectPriority";
import usePortfolio from "../../../hooks/usePortfolio";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { makeStyles } from "@material-ui/core/styles";
import { getUsersUnderAdmin } from "../../../api/project/Project";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import currencyMaskOptions from "../../../@const/currencyMaskOptions";
import { getNumbers } from "../../../utils/getNumber";
import projectComplexity from "../../../@const/projectComplexitys";
import Comment from "./comment/Comment";
import ProjectOtherDetails from "./project-other-details/ProjectOtherDetails";

// import VideoFloatButton from "../../VideoFloatButton";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const ViewAndEditProjectForm = (props) => {
  const { selectedProject, editActive, setEditActive, currentTab, getProject } =
    props;
  const [allProjectTypes, setAllProjectTypes] = useState([]);
  const [projectTypeData, setProjectTypeData] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  const [projectStartDate, setProjectStartDate] = useState(new Date());
  const [projectDueDate, setProjectDueDate] = useState(new Date());
  const [memberList, setMemberList] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState(null);
  const [blurActive, setBlurActive] = useState("");

  const { getAllDropdownPortfolios, dropdownPortfolio } = usePortfolio();
  const classes = useStyles();
  const [formInitialValues, setFormInitialValues] = useState({
    id: "",
    projectName: "",
    projectBudget: "",
    projectContact: "",
    projectPriority: "",
    projectDescription: "",
    projectPortfolioId: "",
    projHours: "",
    spentTillDate: "",
    complexity: "",
  });
  const currencyMask = createNumberMask({
    ...currencyMaskOptions,
  });

  const initialDates = {
    startDate: moment(selectedProject?.start_date),
    dueDate: moment(selectedProject?.due_date),
  };

  useEffect(() => {
    if (selectedProject) {
      const formValues = {
        id: selectedProject?.id,
        projectName: selectedProject?.name,
        projectBudget: selectedProject?.budget,
        projectPriority: selectedProject?.priority,
        projectDescription: selectedProject?.description,
        projectPortfolioId: selectedProject?.portfolio_id,
        projHours: selectedProject?.total_hours,
        spentTillDate: selectedProject?.spent_till_date || 0,
        complexity: selectedProject?.complexity || "",
      };

      setFormInitialValues(formValues);
      const startDatetoArr = selectedProject?.start_date.split("-");
      setProjectStartDate(
        `${startDatetoArr[0]}/${startDatetoArr[1]}/${startDatetoArr[2]}`
      );
      const endDatetoArr = selectedProject?.due_date.split("-");
      setProjectDueDate(
        `${endDatetoArr[0]}/${endDatetoArr[1]}/${endDatetoArr[2]}`
      );

      const projectTypeObj = allProjectTypes.filter((projectType) => {
        if (projectType.id === selectedProject?.type_id) {
          return projectType;
        }
      });
      setProjectTypeData(projectTypeObj[0]);
      setNewProjectName("");
      setTaskAssignedTo(selectedProject?.get_contact_person);
    }
    getMemberList();
  }, [selectedProject, allProjectTypes]);

  const { user } = useAuth();

  const navigate = useNavigate();

  const handleProjectype = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/type`,
        {
          admin: user.id,
          projType: newProjectName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response?.data?.success) {
        getProjectType();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getProjectType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/types`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setAllProjectTypes(response.data.data.projectTypes);
      setNewProjectName("");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (currentTab === "projectDetails") {
      getProjectType();
      getAllDropdownPortfolios();
    }
  }, [currentTab]);

  const handleProjectTypeChange = (e) => {
    e.preventDefault();
    setProjectTypeData(e.target.value);
  };

  const getMemberList = async () => {
    const res = await getUsersUnderAdmin();
    if (res?.data?.success) {
      setMemberList(res.data.data.peopleDropdown);
    } else {
      console.log("No Members");
    }
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val);
  };

  return (
    <>
      {/* <VideoFloatButton tutorialName="projectDetails" /> */}
      <Box sx={{ pb: 2, float: "right" }}>
        {!selectedProject.get_project_budget && (
          <Typography color="secondary">No project budget available</Typography>
        )}
        {selectedProject?.get_project_budget?.isEdited === 1 && (
          <Typography color="secondary">
            You have edited project dates. please change project budget
          </Typography>
        )}
      </Box>
      <ProjectOtherDetails selectedProject={selectedProject} />
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...formInitialValues,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            projectName: Yup.string()
              .max(255)
              .required("Project Name is required"),
            projectBudget: Yup.string().required("Project Budgetis required"),
            complexity: Yup.number().required("Project complexity is required"),
            projectPriority: Yup.number().required(
              "Project Priority is required"
            ),
            projHours: Yup.number().required("Project Hours is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const accessToken = window.localStorage.getItem("accessToken");
              let budgetInfo = {
                needBudgetInfoChange: false,
              };
              if (selectedProject.get_project_budget) {
                const currentStartDate = moment(projectStartDate);
                const currentDueDate = moment(projectDueDate);
                if (
                  initialDates.startDate.format("YYYY-MM") !==
                    currentStartDate.format("YYYY-MM") ||
                  initialDates.dueDate.format("YYYY-MM") !==
                    currentDueDate.format("YYYY-MM")
                ) {
                  const tempDate = currentStartDate.clone();
                  const budgetData = JSON.parse(
                    selectedProject.get_project_budget.data
                  );
                  let monthWiseBudgets = [];
                  while (
                    currentDueDate > tempDate ||
                    tempDate.format("M") === currentDueDate.format("M")
                  ) {
                    const matchPrevDates = budgetData.filter(
                      (obj) => obj?.date === tempDate.format("YYYY-MM")
                    );
                    if (matchPrevDates?.length) {
                      monthWiseBudgets.push(matchPrevDates[0]);
                    } else {
                      monthWiseBudgets.push({
                        date: tempDate.format("YYYY-MM"),
                        budget: 0,
                        actual: 0,
                      });
                    }
                    tempDate.add(1, "month");
                  }
                  budgetInfo = {
                    needBudgetInfoChange: true,
                    isEdited: true,
                    data: monthWiseBudgets,
                  };
                }
              }

              const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/project/${values.id}`,
                {
                  id: values.id,
                  proj_admin: user.id,
                  proj_pf_id: values.projectPortfolioId,
                  proj_name: values.projectName,
                  proj_type: projectTypeData.id,
                  proj_budget: getNumbers(values.projectBudget),
                  proj_priority: values.projectPriority,
                  proj_desc: values.projectDescription,
                  proj_start: moment(projectStartDate).format("YYYY/MM/DD/"),
                  proj_due: moment(projectDueDate).format("YYYY/MM/DD/"),
                  proj_contact: taskAssignedTo?.id || "",
                  proj_hours: values.projHours,
                  proj_spent: getNumbers(values.spentTillDate),
                  proj_complex: values.complexity,
                  budgetInfo: budgetInfo,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
              if (response.data.success) {
                setEditActive(false);
                getProject();
                toast.success("Project Updated Successfully");
                // navigate("/dashboard/projects/view-all-projects");
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Project Name"
                    required
                    disabled={!editActive}
                    error={Boolean(touched.projectName && errors.projectName)}
                    fullWidth
                    helperText={touched.projectName && errors.projectName}
                    margin="normal"
                    name="projectName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projectName}
                    variant="outlined"
                    sx={{ mt: 0 }}
                  />
                </Grid>
                {dropdownPortfolio?.length > 0 && (
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Link Project with Portfolio"
                      shrink
                      disabled={!editActive}
                      error={Boolean(
                        touched.projectPortfolioId && errors.projectPortfolioId
                      )}
                      fullWidth
                      helperText={
                        touched.projectPortfolioId && errors.projectPortfolioId
                      }
                      margin="normal"
                      name="projectPortfolioId"
                      select
                      SelectProps={{ native: true }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectPortfolioId}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    >
                      {/* <option disabled selected>
                          Select Portfolios
                        </option> */}
                      {dropdownPortfolio?.map((portfolio) => (
                        <option value={portfolio.id}>{portfolio.name}</option>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      disabled={!editActive}
                      id="demo-simple-select-label"
                      required
                    >
                      Project Type
                    </InputLabel>
                    <Select
                      label=" Project Type * "
                      required
                      disabled={!editActive}
                      fullWidth
                      margin="normal"
                      name="projectTypeData"
                      value={projectTypeData}
                      onChange={handleProjectTypeChange}
                      renderValue={() => projectTypeData.type_name}
                    >
                      {allProjectTypes.length > 0 &&
                        allProjectTypes.map((projectType) => (
                          <MenuItem key={projectType?.id} value={projectType}>
                            <Radio
                              checked={projectType?.id === projectTypeData?.id}
                            />
                            <ListItemText primary={projectType?.type_name} />
                          </MenuItem>
                        ))}
                      <Box sx={{ px: 3, py: 2 }}>
                        <TextField
                          disabled={!editActive}
                          fullWidth
                          onKeyDown={(e) => e.stopPropagation()}
                          margin="normal"
                          name="addNewProjectType"
                          onChange={(e) => setNewProjectName(e.target.value)}
                          value={newProjectName}
                          variant="outlined"
                          sx={{ mt: 0 }}
                        ></TextField>
                        <Button
                          color="primary"
                          size="large"
                          variant="contained"
                          fullWidth
                          sx={{ py: 2, mt: 1 }}
                          onClick={handleProjectype}
                        >
                          Save
                        </Button>
                      </Box>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  {/* <TextField
                    label="Estimated Budget "
                    required
                    disabled={!editActive}
                    error={Boolean(
                      touched.projectBudget && errors.projectBudget
                    )}
                    fullWidth
                    helperText={touched.projectBudget && errors.projectBudget}
                    margin="normal"
                    name="projectBudget"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projectBudget}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon sx={{ color: "#555" }} />
                        </InputAdornment>
                      ),
                    }}
                  /> */}

                  <FormControl
                    required
                    fullWidth
                    className={`${classes.formControl} formikInputWrapper ${
                      blurActive === "projectBudget" ? "blured" : ""
                    }`}
                    error={errors.projectBudget && touched.projectBudget}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ backgroundColor: "#fff", px: 1 }}
                      shrink
                    >
                      Budget
                    </InputLabel>

                    <Box className="custom-mui-input-wrapper">
                      <Field
                        name="projectBudget"
                        render={({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={currencyMask}
                            id="projectBudget"
                            placeholder="$"
                            type="text"
                            onChange={handleChange}
                            value={values.projectBudget}
                            disabled={!editActive}
                            onBlur={(e) => {
                              setBlurActive("");
                              handleBlur(e);
                            }}
                            onFocus={() => {
                              setBlurActive("projectBudget");
                            }}
                            className={
                              errors.projectBudget && touched.projectBudget
                                ? "text-input error custom-mui-input"
                                : "text-input custom-mui-input"
                            }
                          />
                        )}
                      />
                    </Box>
                    <FormHelperText>
                      {touched.projectBudget && errors.projectBudget}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  {/* <TextField
                    label="Spent To Date "
                    required
                    disabled={!editActive}
                    error={Boolean(
                      touched.spentTillDate && errors.spentTillDate
                    )}
                    fullWidth
                    helperText={touched.spentTillDate && errors.spentTillDate}
                    margin="normal"
                    name="spentTillDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.spentTillDate}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon sx={{ color: "#555" }} />
                        </InputAdornment>
                      ),
                    }}
                  /> */}

                  <FormControl
                    required
                    fullWidth
                    className={`${classes.formControl} formikInputWrapper ${
                      blurActive === "spentTillDate" ? "blured" : ""
                    }`}
                    error={errors.spentTillDate && touched.spentTillDate}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{ backgroundColor: "#fff", px: 1 }}
                      shrink
                    >
                      Spent To Date
                    </InputLabel>

                    <Box className="custom-mui-input-wrapper">
                      <Field
                        name="spentTillDate"
                        render={({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={currencyMask}
                            id="spentTillDate"
                            placeholder="$"
                            type="text"
                            disabled={!editActive}
                            onChange={handleChange}
                            value={values.spentTillDate}
                            onBlur={(e) => {
                              setBlurActive("");
                              handleBlur(e);
                            }}
                            onFocus={() => {
                              setBlurActive("spentTillDate");
                            }}
                            className={
                              errors.spentTillDate && touched.spentTillDate
                                ? "text-input error custom-mui-input"
                                : "text-input custom-mui-input"
                            }
                          />
                        )}
                      />
                    </Box>
                    <FormHelperText>
                      {touched.projectBudget && errors.projectBudget}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    blurOnSelect={true}
                    options={memberList}
                    value={taskAssignedTo}
                    onChange={handleUChange}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    disabled={!editActive}
                    renderOption={(props, option) => {
                      return (
                        <li
                          {...props}
                          key={`${option.first_name} ${option.last_name} ${option.id}`}
                        >
                          {`${option.first_name} ${option.last_name}`}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Contact Person" />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    label="Project Start Date "
                    required
                    autoOk={true}
                    className="pt-dtpicker"
                    fullWidth
                    disabled={!editActive}
                    onChange={(date) => {
                      setProjectStartDate(date);
                      if (moment(date) > moment(projectDueDate)) {
                        setProjectDueDate(date);
                      }
                    }}
                    value={projectStartDate}
                    format="MM/DD/YYYY"
                    name="projectStartDate"
                    renderInput={(inputProps) => (
                      <TextField
                        className="pt-datepicker-ip"
                        fullWidth
                        variant="outlined"
                        {...inputProps}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    label="Project End Date "
                    required
                    disabled={!editActive}
                    autoOk={true}
                    className="pt-dtpicker"
                    fullWidth
                    onChange={(date) => setProjectDueDate(date)}
                    value={projectDueDate}
                    name="projectDueDate"
                    minDate={projectStartDate}
                    renderInput={(inputProps) => (
                      <TextField
                        className="pt-datepicker-ip"
                        fullWidth
                        variant="outlined"
                        {...inputProps}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Project Priority "
                    required
                    disabled={!editActive}
                    error={Boolean(
                      touched.projectPriority && errors.projectPriority
                    )}
                    fullWidth
                    helperText={
                      touched.projectPriority && errors.projectPriority
                    }
                    margin="normal"
                    name="projectPriority"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projectPriority}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    placeholder="Select project priority"
                  >
                    <option>Select project priority</option>
                    {projectPriority.map((priority) => (
                      <option map={priority.id} value={priority.value}>
                        {priority.title}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl
                    className={classes.formControl}
                    error={Boolean(touched.complexity && errors.complexity)}
                    required
                    disabled={!editActive}
                  >
                    <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                      Project complexity{" "}
                    </InputLabel>
                    <Select
                      labelId="select-status"
                      label="Project complexity *"
                      name="complexity"
                      value={values.complexity}
                      fullWidth
                      placeholder="Status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {projectComplexity.map((comp) => (
                        <MenuItem key={comp.value} value={comp.value}>
                          {comp.title}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.complexity && errors.complexity}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Estimated Hours"
                    required
                    disabled={!editActive}
                    error={Boolean(touched.projHours && errors.projHours)}
                    fullWidth
                    helperText={touched.projHours && errors.projHours}
                    margin="normal"
                    name="projHours"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projHours}
                    variant="outlined"
                    sx={{ mt: 0 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Project Description"
                    disabled={!editActive}
                    error={Boolean(
                      touched.projectDescription && errors.projectDescription
                    )}
                    fullWidth
                    helperText={
                      touched.projectDescription && errors.projectDescription
                    }
                    margin="normal"
                    name="projectDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projectDescription}
                    variant="outlined"
                    sx={{ mt: 0 }}
                    rows={2}
                    multiline
                  />
                </Grid>
              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              {editActive && (
                <Box sx={{ display: "flex", mr: "auto", pb: 1, mt: 3 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>

                  <Box sx={{ px: 1 }}>
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      onClick={() => setEditActive(false)}
                    >
                      cancel
                    </Button>
                  </Box>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </Box>
      <Comment projectId={selectedProject.id} />
    </>
  );
};

export default ViewAndEditProjectForm;

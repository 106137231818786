import React, { useState, useEffect } from "react";
import {
  FormHelperText,
  Card,
  TextField,
  Box,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  ListItemText,
  FormControl,
  InputLabel,
  Radio,
  Autocomplete,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import axios from "axios";
import projectPriority from "../../../@const/projectPriority";
import DatePicker from "@material-ui/lab/DatePicker";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";
import usePortfolio from "../../../hooks/usePortfolio";
import useProject from "../../../hooks/useProject";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getUsersUnderAdmin } from "../../../api/project/Project";
import { makeStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import currencyMaskOptions from "../../../@const/currencyMaskOptions";
import { getNumbers } from "../../../utils/getNumber";
import CreateNewMemberModal from "../people/CreateNewMemberModal";
import useCommons from "../../../hooks/useCommons";
import projectComplexity from "../../../@const/projectComplexitys";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CreateProjectForm = (props) => {
  const [allProjectTypes, setAllProjectTypes] = useState([]);
  const [projectTypeData, setProjectTypeData] = useState("");
  const [newProjectName, setNewProjectName] = useState("");
  const [projectStartDate, setProjectStartDate] = useState(new Date());
  const [projectDueDate, setProjectDueDate] = useState(new Date());
  const [memberList, setMemberList] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState(null);
  const [blurActive, setBlurActive] = useState(false);

  const { user } = useAuth();
  const { getProjectCount } = useProject();
  const { getAllDropdownPortfolios, dropdownPortfolio } = usePortfolio();

  const navigate = useNavigate();
  const classes = useStyles();

  const currencyMask = createNumberMask({
    ...currencyMaskOptions,
  });

  const handleProjectype = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/type`,
        {
          admin: user.id,
          projType: newProjectName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response?.data?.success) {
        getProjectType();
      }
      setNewProjectName("");
    } catch (err) {
      console.error(err);
    }
  };

  const getProjectType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/types`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setAllProjectTypes(response.data.data.projectTypes);
      setNewProjectName("");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProjectType();
    getAllDropdownPortfolios();
    getMemberList();
  }, []);

  const handleProjectTypeChange = (e) => {
    e.preventDefault();
    setProjectTypeData(e.target.value);
  };

  const getMemberList = async () => {
    const res = await getUsersUnderAdmin();
    if (res.data.success) {
      setMemberList(res.data.data.peopleDropdown);
    } else {
      console.log("No Members");
    }
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val?.id || "");
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            m: -1,
            p: 3,
          }}
        >
          <Typography
            variant="dashboardContentHeader"
            sx={{
              color: "#2D3E56",
              mb: "34px",
              display: "block",
            }}
          >
            Add New Project
          </Typography>
          <Formik
            // enableReinitialize={true}
            initialValues={{
              projectName: "",
              projectBudget: 0,
              projectPriority: "",
              projectDescription: "",
              projectPortfolioId: "",
              projectHours: "",
              projectTypeData: projectTypeData,
              cPerson: taskAssignedTo,
              complexity: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              projectName: Yup.string()
                .max(255)
                .required("Project Name is required"),
              projectBudget: Yup.string().required(
                "Project Budget is required"
              ),
              projectPriority: Yup.number().required(
                "Project Priority is required"
              ),
              complexity: Yup.number().required(
                "Project complexity is required"
              ),
              projectTypeData: Yup.object().required(
                "Project Type is required"
              ),
              cPerson: Yup.number()
                .typeError("Select a valid person")
                .nullable(),
              projectHours: Yup.number().required("Project Hours is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const accessToken = window.localStorage.getItem("accessToken");

                const response = await axios.post(
                  `${process.env.REACT_APP_BACKEND_URL}/api/project`,
                  {
                    proj_admin: user.id,
                    proj_pf_id: values.projectPortfolioId,
                    proj_name: values.projectName,
                    proj_type: projectTypeData.id,
                    proj_budget: values.projectBudget,
                    proj_contact: taskAssignedTo || "",
                    proj_priority: values.projectPriority,
                    proj_hours: values.projectHours,
                    proj_desc: values.projectDescription,
                    proj_complex: values.complexity,
                    proj_start: moment(projectStartDate).format("YYYY/MM/DD/"),
                    proj_due: moment(projectDueDate).format("YYYY/MM/DD/"),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }
                );

                if (response.data.success) {
                  toast.success(response.data.message);
                  getProjectCount();
                  navigate("/dashboard/projects/view-all-projects");
                }
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Project Name"
                      required
                      error={Boolean(touched.projectName && errors.projectName)}
                      fullWidth
                      helperText={touched.projectName && errors.projectName}
                      margin="normal"
                      name="projectName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectName}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  {dropdownPortfolio?.length > 0 && (
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        label="Link Project with Portfolio"
                        error={Boolean(
                          touched.projectPortfolioId &&
                            errors.projectPortfolioId
                        )}
                        fullWidth
                        helperText={
                          touched.projectPortfolioId &&
                          errors.projectPortfolioId
                        }
                        margin="normal"
                        name="projectPortfolioId"
                        select
                        SelectProps={{ native: true }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.projectPortfolioId}
                        variant="outlined"
                        sx={{ mt: 0 }}
                      >
                        <option disabled value=""></option>

                        {dropdownPortfolio?.map((portfolio) => (
                          <option value={portfolio.id}>{portfolio.name}</option>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      error={Boolean(
                        touched.projectTypeData && errors.projectTypeData
                      )}
                      required
                    >
                      <InputLabel id="demo-simple-select-label">
                        Project Type
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        label="Project Type *"
                        margin="normal"
                        name="projectTypeData"
                        value={projectTypeData}
                        onChange={(e) => {
                          handleProjectTypeChange(e);
                          setFieldValue("projectTypeData", e.target.value);
                        }}
                        onBlur={handleBlur}
                        renderValue={() => projectTypeData.type_name}
                      >
                        {allProjectTypes.map((projectType) => (
                          <MenuItem key={projectType.id} value={projectType}>
                            <Radio
                              checked={projectType.id === projectTypeData.id}
                            />
                            <ListItemText primary={projectType.type_name} />
                          </MenuItem>
                        ))}
                        <Box sx={{ px: 3, py: 2 }}>
                          <TextField
                            fullWidth
                            onKeyDown={(e) => e.stopPropagation()}
                            margin="normal"
                            name="addNewProjectType"
                            onChange={(e) => setNewProjectName(e.target.value)}
                            value={newProjectName}
                            variant="outlined"
                            placeholder="Add custom project type"
                            sx={{ mt: 0 }}
                          ></TextField>
                          <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            fullWidth
                            sx={{ py: 2, mt: 1 }}
                            onClick={handleProjectype}
                          >
                            Save
                          </Button>
                        </Box>
                      </Select>
                      <FormHelperText>
                        {touched.projectTypeData && errors.projectTypeData}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={4}>

                    <FormControl
                      required
                      fullWidth
                      className={`${classes.formControl} formikInputWrapper ${
                        blurActive ? "blured" : ""
                      }`}
                      error={errors.projectBudget && touched.projectBudget}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{ backgroundColor: "#fff", px: 1 }}
                        shrink
                      >
                        Estimated Budget
                      </InputLabel>

                      <Box className="custom-mui-input-wrapper">
                        <Field
                          name="projectBudget"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={currencyMask}
                              id="projectBudget"
                              placeholder="$"
                              type="text"
                              onChange={handleChange}
                              value={values.projectBudget}
                              onBlur={(e) => {
                                setBlurActive(false);
                                handleBlur(e);
                              }}
                              onFocus={() => {
                                setBlurActive(true);
                              }}
                              className={
                                errors.projectBudget && touched.projectBudget
                                  ? "text-input error custom-mui-input"
                                  : "text-input custom-mui-input"
                              }
                            />
                          )}
                        />
                      </Box>
                      <FormHelperText>
                        {touched.projectBudget && errors.projectBudget}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      blurOnSelect={true}
                      options={memberList}
                      onChange={handleUChange}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={`${option.first_name} ${option.last_name} ${option.id}`}
                          >
                            {`${option.first_name} ${option.last_name}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contact Person"
                          onBlur={handleBlur}
                          name="cPerson"
                          helperText={touched.cPerson && errors.cPerson}
                          error={touched.cPerson && errors.cPerson}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      required
                      error={!projectStartDate}
                    >
                      <InputLabel id="demo-simple-select-label" shrink>
                        Project Start Date
                      </InputLabel>
                      <DatePicker
                        label="Project Start Date "
                        autoOk={true}
                        className="pt-dtpicker"
                        fullWidth
                        onChange={(date) => {
                          setProjectDueDate(date);
                          setProjectStartDate(date);
                        }}
                        value={projectStartDate}
                        name="projectStartDate"
                        renderInput={(inputProps) => (
                          <TextField
                            className="pt-datepicker-ip"
                            fullWidth
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      required
                      error={!projectDueDate}
                    >
                      <InputLabel id="demo-simple-select-label" shrink>
                        Project End Date
                      </InputLabel>
                      <DatePicker
                        label="Project End Date "
                        autoOk={true}
                        className="pt-dtpicker"
                        fullWidth
                        onChange={(date) => setProjectDueDate(date)}
                        value={projectDueDate}
                        name="projectDueDate"
                        minDate={projectStartDate}
                        renderInput={(inputProps) => (
                          <TextField
                            fullWidth
                            className="pt-datepicker-ip"
                            variant="outlined"
                            {...inputProps}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Project Priority"
                      required
                      error={Boolean(
                        touched.projectPriority && errors.projectPriority
                      )}
                      fullWidth
                      helperText={
                        touched.projectPriority && errors.projectPriority
                      }
                      margin="normal"
                      name="projectPriority"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectPriority}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    >
                      <option value=""></option>
                      {projectPriority.map((priority) => (
                        <option map={priority.id} value={priority.value}>
                          {priority.title}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      className={classes.formControl}
                      error={Boolean(touched.complexity && errors.complexity)}
                      required
                    >
                      <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                        Project complexity{" "}
                      </InputLabel>
                      <Select
                        labelId="select-status"
                        label="Project complexity *"
                        name="complexity"
                        value={values.complexity}
                        fullWidth
                        placeholder="Status"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {projectComplexity.map((comp) => (
                          <MenuItem key={comp.value} value={comp.value}>
                            {comp.title}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.complexity && errors.complexity}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Estimated Hours"
                      required
                      error={Boolean(
                        touched.projectHours && errors.projectHours
                      )}
                      fullWidth
                      helperText={touched.projectHours && errors.projectHours}
                      margin="normal"
                      name="projectHours"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectHours}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Project Description"
                      error={Boolean(
                        touched.projectDescription && errors.projectDescription
                      )}
                      fullWidth
                      helperText={
                        touched.projectDescription && errors.projectDescription
                      }
                      margin="normal"
                      name="projectDescription"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.projectDescription}
                      variant="outlined"
                      sx={{ mt: 0 }}
                      rows={2}
                      multiline
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <Box sx={{ display: "flex", mr: "auto", pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

export default CreateProjectForm;

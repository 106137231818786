import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  Pagination,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import useTable from "../../../hooks/useTable";
import useAuth from "../../../hooks/useAuth";
import CreateNewDepartmentModal from "./CreateNewDepartmentModal";
import SingleDepartment from "./SingleDepartment";
import { getAlldepartment } from "../../../api/department/Department";
import useLoader from "../../../hooks/useLoader";


const ViewAllDepartmentListTable = (props) => {
  const { showAddDepartmentModal, setShowAddDepartmentModal, ...other } = props;
  const { permissions, user } = useAuth();
  const headCells = [
    { id: "department", label: "DEPARTMENT NAME" },
    { id: "total_member", label: "TOTAL MEMBER", disableSorting: true },
    { id: "dept_head ", label: "DEPARTMENT HEAD" },
    { id: "email", label: "EMAIL" },
  ];

  if (permissions["DEPARTMENT_UPDATE"] || permissions["DEPARTMENT_DELETE"]) {
    headCells.push({
      id: "action",
      label: "ACTION",
      disableSorting: true,
      align: "center",
    });
  }

  const [currentPage, setCurrentPage] = useState(1);

  const [department, setDepartment] = useState([]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    department.data,
    headCells
  );

  const { loaderState, loaderStart, loaderEnd } = useLoader();

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getAllDepartmentsUnderAdmin();
  }, [currentPage]);

  const getAllDepartmentsUnderAdmin = async () => {
    loaderStart();
    const res = await getAlldepartment({ adminId: user.id, page: currentPage });
    if (res?.data?.success) {
      setDepartment(res.data.data.departments);
    }
    loaderEnd();
  };

  return (
    <>
      <CreateNewDepartmentModal
        showAddDepartmentModal={showAddDepartmentModal}
        setShowAddDepartmentModal={setShowAddDepartmentModal}
        getAllDepartmentsUnderAdmin={getAllDepartmentsUnderAdmin}
      />
      <Card {...other}>
        <Box
          sx={{
            m: -1,
            p: 3,
          }}
        >
          <Typography
            variant="dashboardContentHeader"
            sx={{
              color: "#2D3E56",
              mb: "18px",
              display: "block",
            }}
          >
            All Departments
          </Typography>
          <Box
            sx={{
              m: 1,
              maxWidth: "100%",
              width: 240,
            }}
          ></Box>
        </Box>
        <Box sx={{ pt: 3 }}>
          <TblContainer>
            <TblHead />
            {department?.data?.length > 0 && (
              <TableBody>
                {recordsAfterPagingAndSorting().map(
                  (singleDepartment, index) => {
                    return (
                      <SingleDepartment
                        getAllDepartmentsUnderAdmin={
                          getAllDepartmentsUnderAdmin
                        }
                        singleDepartment={singleDepartment}
                        key={index}
                        index={index}
                      />
                    );
                  }
                )}
              </TableBody>
            )}
          </TblContainer>
          <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
            {department?.data?.length > 0 ? (
              <Pagination
                page={currentPage}
                onChange={handleChange}
                count={department.last_page}
              />
            ) : (
              <Typography variant="h6" color="secondary">
                {loaderState ? "Loading..." : "No Records Available"}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ViewAllDepartmentListTable;

import { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import useLoader from "../hooks/useLoader";
import useAuth from "../hooks/useAuth";
import { handleApiRequest } from "../hooks/callApi";
import { getUsersUnderAdmin } from "../api/project/Project";
import { getAllPortfolioTypes } from "../api/portfolio/Portfolio";
import { getAllTutorials } from "../api/tutorials/Tutorials";
import { getStage } from "../api/admin/Stage";

const initialState = {
  allPeopleCount: 0,
  allDepartmentsCount: 0,
  allReportCount: 0,
  showAddMemberModal: false,
  dropdownMemberList: [],
  portfolioTypes: [],
  tutorials: [],
  stages: [],
  currentPath: null,
  isSidebarMobileOpen: true,
  initialRedirectURL: null,
};

const handlers = {
  UPDATECOUNT: (state, action) => {
    const { allPeopleCount, allDepartmentsCount, allReportCount } =
      action.payload;

    return {
      ...state,
      allPeopleCount,
      allDepartmentsCount,
      allReportCount,
    };
  },
  UPDATEADDMEMBERMODALSTATE: (state, action) => {
    const { showAddMemberModal } = action.payload;

    return {
      ...state,
      showAddMemberModal,
    };
  },
  UPDATEDROPDOWNMEMBERLIST: (state, action) => {
    const { dropdownMemberList } = action.payload;

    return {
      ...state,
      dropdownMemberList,
    };
  },

  UPDATEPORTFOLIOTYPES: (state, action) => {
    const { portfolioTypes } = action.payload;

    return {
      ...state,
      portfolioTypes,
    };
  },

  UPDATETUTORIALS: (state, action) => {
    const { tutorials } = action.payload;

    return {
      ...state,
      tutorials,
    };
  },

  UPDATECURRENTPATH: (state, action) => {
    const { currentPath } = action.payload;

    return {
      ...state,
      currentPath,
    };
  },

  UPDATESIDEBARSTATE: (state, action) => {
    const { isSidebarMobileOpen } = action.payload;

    return {
      ...state,
      isSidebarMobileOpen,
    };
  },

  GETSTAGES: (state, action) => {
    const { stages } = action.payload;

    return {
      ...state,
      stages,
    };
  },
  UPDATEINITIALREDIRECTURL: (state, action) => {
    const { initialRedirectURL } = action.payload;

    return {
      ...state,
      initialRedirectURL,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const CommonContext = createContext({
  ...initialState,
  getCounts: () => Promise.resolve(),
  setShowAddMemberModal: () => Promise.resolve(),
  getDropdownMemberList: () => Promise.resolve(),
  getPortfolioTypes: () => Promise.resolve(),
  getTutorials: () => Promise.resolve(),
  updateCurrentPath: () => Promise.resolve(),
  setIsSidebarMobileOpen: () => Promise.resolve(),
  getStages: () => Promise.resolve(),
  setInitialRedirectURL: () => Promise.resolve(),
});

export const CommonProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loaderStart, loaderEnd } = useLoader();
  const { user } = useAuth();
  useEffect(() => {
    if (user?.id) {
      getCounts();
      getTutorials();
      getStages();
    }
  }, [user]);

  const getCounts = async () => {
    try {
      loaderStart();
      const deptRes = await handleApiRequest(`/api/department/count`, "get");
      const peopleRes = await handleApiRequest(`/api/people/count`, "get");
      const reportRes = await handleApiRequest(`/api/report/count`, "get");

      dispatch({
        type: "UPDATECOUNT",
        payload: {
          allDepartmentsCount: deptRes?.data?.data?.count || 0,
          allPeopleCount: peopleRes?.data?.data?.count || 0,
          allReportCount: reportRes?.data?.data?.count || 0,
        },
      });
      loaderEnd();
    } catch (error) {
      console.log(error);
      loaderEnd();
    }
  };

  const setShowAddMemberModal = (modalState) => {
    dispatch({
      type: "UPDATEADDMEMBERMODALSTATE",
      payload: {
        showAddMemberModal: modalState,
      },
    });
  };

  const getDropdownMemberList = async () => {
    const res = await getUsersUnderAdmin();
    if (res?.data?.success) {
      dispatch({
        type: "UPDATEDROPDOWNMEMBERLIST",
        payload: {
          dropdownMemberList: res?.data?.data.peopleDropdown,
        },
      });
    } else {
      console.log("No Members");
    }
  };

  const getPortfolioTypes = async () => {
    const res = await getAllPortfolioTypes();
    if (res?.data?.success) {
      dispatch({
        type: "UPDATEPORTFOLIOTYPES",
        payload: {
          portfolioTypes: res.data.data.portfolioTypes,
        },
      });
    } else {
      console.log("No Portfolio Types");
    }
  };

  const getTutorials = async () => {
    const allTutorials = await getAllTutorials();

    dispatch({
      type: "UPDATETUTORIALS",
      payload: {
        tutorials: allTutorials.data.data.items,
      },
    });
  };

  const getStages = async () => {
    const res = await getStage();

    dispatch({
      type: "GETSTAGES",
      payload: {
        stages: res.data.data.stages,
      },
    });
  };

  const updateCurrentPath = () => {
    dispatch({
      type: "UPDATECURRENTPATH",
      payload: {
        currentPath: window.location.href,
      },
    });
  };

  const setIsSidebarMobileOpen = (isOpen) => {
    dispatch({
      type: "UPDATESIDEBARSTATE",
      payload: {
        isSidebarMobileOpen: isOpen || !state.isSidebarMobileOpen,
      },
    });
  };

  const setInitialRedirectURL = (path) => {
    dispatch({
      type: "UPDATEINITIALREDIRECTURL",
      payload: {
        initialRedirectURL: path || null,
      },
    });
  };

  return (
    <CommonContext.Provider
      value={{
        ...state,
        getCounts,
        setShowAddMemberModal,
        getDropdownMemberList,
        getPortfolioTypes,
        updateCurrentPath,
        setIsSidebarMobileOpen,
        getStages,
        setInitialRedirectURL,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

CommonProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommonContext;

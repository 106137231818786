import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import MyAllTaskPortal from "../../components/dashboard/mytaskportal/MyAllTaskPortal";
import ChevronRightIcon from "../../icons/ChevronRight";
const MyTaskPortal = () => {
  const { settings } = useSettings();
  return (
    <>
      <Helmet>
        <title>Dashboard: My Tasks Portal |</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Projects
                </Typography>
                <Typography color="textSecondary" variant="breadCrumbText">
                  My Tasks Portal
                </Typography>
              </Breadcrumbs>
              <Typography
                Tasks
                Portal
                color="textPrimary"
                variant="dashboardHeading"
              >
                My Tasks Portal
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}></Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <MyAllTaskPortal />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MyTaskPortal;

import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { projectWorkloadGraph } from "../../../../api/project/ProjectDashboard";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const WorkloadGraph = ({ selectedProject, authToken = null }) => {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [],
  });

  useEffect(() => {
    getGraph();
  }, []);

  const getGraph = async () => {
    const res = await projectWorkloadGraph(selectedProject, authToken);
    if (res?.data?.data?.values) {
      setOptions({
        ...options,
        series: res.data.data.values,
        xAxis: {
          categories: res.data.data.categories,
        },
      });
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default WorkloadGraph;

import axios from "axios";
import { handleApiRequest } from "../../hooks/callApi";

export const addNewPeople = async (memberData) => {
  const body = memberData;

  const res = await handleApiRequest(`/api/people`, "post", body);

  return res;
};

export const getAllPeople = async ({ page, uname, sort }) => {
  const res = await handleApiRequest(
    `/api/people-search?page=${page || 1}&uname=${uname || ""}&sort=${
      sort || ""
    }`,
    "get"
  );
  return res;
};

export const getSinglePeople = async (userId) => {
  const res = await handleApiRequest(`/api/people/${userId}`, "get");
  return res;
};

export const editPeople = async (memberData, userId) => {
  const body = memberData;
  const res = await handleApiRequest(`/api/people/${userId}`, "put", body);

  return res;
};

export const removePeople = async (peopleId) => {
  const res = await handleApiRequest(`/api/people/${peopleId}`, "delete");

  return res;
};

export const getWorksWithUserList = async (projectId) => {
  const res = await handleApiRequest(
    `/api/project/members/${projectId}`,
    "get"
  );

  return res;
};

import { useState, useEffect, useCallback } from "react";
import { Link as RouterLink, useParams, Prompt } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
  Button,
  Card,
  Tab,
  Tabs,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import ViewAndEditProjectForm from "../../components/dashboard/projects/ViewAndEditProjectForm";
import AllTasks from "../../components/dashboard/projects/AllTasks";
import Stages from "../../components/dashboard/projects/Stages";
import Score from "../../components/dashboard/projects/Score";
import ProjectStatus from "../../components/dashboard/projects/ProjectStatus";
import Members from "../../components/dashboard/projects/Members";
import Milestones from "../../components/dashboard/projects/Milestones";
import Issues from "../../components/dashboard/projects/Issues";
import Documents from "../../components/dashboard/projects/Documents";
import useProject from "../../hooks/useProject";
import PencilAltIcon from "../../icons/PencilAlt";
import AllTasksBoardView from "../../components/dashboard/projects/AllTasksBoardView";
import AllTasksGanttChartView from "../../components/dashboard/projects/AllTasksGanttChartView";
import AllTasksTreeView from "../../components/dashboard/projects/AllTasksTreeView";
import ProjectStageGraphic from "../../components/dashboard/projects/ProjectStageGraphic";
import ProjectStatusGraphics from "../../components/dashboard/projects/ProjectStatusGraphics";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  AccountTreeOutlined,
  AssignmentOutlined,
  ClearAll,
} from "@material-ui/icons";
import useCommons from "../../hooks/useCommons";
import MonthWiseBudget from "../../components/dashboard/projects/budget/MonthWiseBudget";
import ProjectDashboard from "../../components/dashboard/projects/ProjectDashboard";
import AddToTemplateModal from "../../components/dashboard/projects/AddToTemplateModal";
// import permissions from "../../@const/permissions";
import PermissionGuard from "../../components/PermissionGuard";
import useAuth from "../../hooks/useAuth";
import Measure from "../../components/dashboard/projects/measure";

const allTasksTab = [
  {
    label: "List",
    value: "listView",
    icon: <FormatListBulletedIcon className="chart-icon" />,
  },
  {
    label: "Board",
    value: "boardView",
    icon: <AssignmentOutlined className="chart-icon" />,
  },
  {
    label: "Tree",
    value: "treeView",
    icon: <AccountTreeOutlined className="chart-icon" />,
  },
  {
    label: "Gantt Chart",
    value: "ganttChatView",
    icon: <ClearAll className="chart-icon" />,
  },
];

const ProjectPreview = ({ selectedProjectId }) => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const activeTabParam = parameters.get("p");
  const activeTaskParam = parameters.get("t");
  const { permissions } = useAuth();

  const tabs = [
    { label: "Dashboard", value: "dashboard" },
    { label: "Project Details", value: "projectDetails" },
    // { label: "Score", value: "score" },
    { label: "Milestones", value: "milestones" },
    { label: "Members", value: "members" },
    { label: "Task", value: "allTasks" },
    // { label: "Stages", value: "stages" },
    // { label: "Project Status", value: "projectStatus" },
    // { label: "Issues", value: "issues" },
    // { label: "Documents", value: "documents" },
    // { label: "Measure", value: "measure" },
  ];

  if (permissions["PROJECT_BUDGET_VIEW"]) {
    tabs.splice(2, 0, { label: "Budget", value: "budget" });
  }

  const [selectedProject, setSelectedProject] = useState(null);
  const [editActive, setEditActive] = useState(false);
  const [currentTab, setCurrentTab] = useState(activeTabParam || "dashboard");
  const [activeAllTaskTab, setActiveAllTaskTab] = useState(
    activeTaskParam || "listView"
  );
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showAddPhysicalDoumentModal, setShowAddPhysicalDoumentModal] =
    useState(false);
  const [showAddDoumentLinkModal, setShowAddDoumentLinkModal] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showAddMilestoneModal, setShowAddMilestoneModal] = useState(false);
  const [showAddIssueodal, setShowAddIssueodal] = useState(false);
  const [open, setOpen] = useState(false);
  const [tempTabValue, setTempTabValue] = useState(null);
  const [showImportTaskModal, setShowImportTaskModal] = useState(false);
  const [showAddToTemplate, setShowUseAddToTemplate] = useState(false);

  const { settings } = useSettings();

  const projectId = selectedProjectId;

  const { getSingleProject } = useProject();

  const { updateCurrentPath } = useCommons();

  useEffect(() => {
    getProject();
  }, []);

  const getProject = async () => {
    try {
      const project = await getSingleProject(projectId);
      setSelectedProject(project);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabsChange = (event, value) => {
    if (editActive) {
      setTempTabValue(value);
      setOpen(true);
    } else {
      setCurrentTab(value);
    }
  };

  const resetFormAndRedirect = () => {
    if (tempTabValue) {
      setCurrentTab(tempTabValue);
      setEditActive(false);
      handleClose();
      setTempTabValue(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>Dashboard: View and Edit project</title>
      </Helmet>

      <Prompt
        when={editActive}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ pt: 3, maxWidth: "350px", textAlign: "center" }}
          >
            You might have unsaved changes, are you sure you want to leave?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={resetFormAndRedirect} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <AddToTemplateModal
        setShowUseAddToTemplate={setShowUseAddToTemplate}
        showAddToTemplate={showAddToTemplate}
        projectId={selectedProject?.id}
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Card>
            <Box>
              <Box sx={{ borderBottom: "1px solid #D7DFEC" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 3,
                    height: "100px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      backgroundColor: "#ddd",
                      zIndex: "1",
                      height: "100%",
                      width: "100%",
                      opacity: "0.15",
                    }}
                  ></Box>
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ProjectStatusGraphics
                        selectedProject={selectedProject}
                        getProject={getProject}
                      />
                      <Typography
                        variant="dashboardContentHeader"
                        sx={{
                          color: "#2D3E56",
                          display: "block",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedProject?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      backgroundColor: "#ddd",
                      zIndex: "1",
                      height: "100%",
                      width: "100%",
                      opacity: "0.15",
                      pb: 1,
                    }}
                  ></Box>
                  <ProjectStageGraphic
                    selectedProject={selectedProject}
                    getProject={getProject}
                  />
                </Box>
                <Box>
                  <Tabs
                    indicatorColor="primary"
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    textColor="primary"
                    value={currentTab}
                    variant="scrollable"
                    sx={{
                      ".MuiTab-wrapper": {
                        textTransform: "none",
                        fontSize: "12px",
                      },
                      ".Mui-selected": {
                        fontWeight: "bold",
                        color: "#000",
                      },
                      button: {
                        minWidth: "80px",
                        "&:hover": {
                          backgroundColor: "#eee",
                        },
                      },
                      ".MuiTabs-indicator": {
                        minWidth: "60px",
                      },
                      px: 2,
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              {selectedProject && (
                <Box sx={{ mt: 1, px: 3, py: 2, position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      backgroundColor: "#ddd",
                      zIndex: "1",
                      height: "100%",
                      width: "100%",
                      opacity: "0.15",
                    }}
                  ></Box>
                  {currentTab === "projectDetails" && (
                    <ViewAndEditProjectForm
                      selectedProject={selectedProject}
                      editActive={editActive}
                      setEditActive={setEditActive}
                      currentTab={currentTab}
                      getProject={getProject}
                    />
                  )}
                  {currentTab === "allTasks" &&
                    (activeAllTaskTab === "listView" ? (
                      <AllTasks
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                        showImportTaskModal={showImportTaskModal}
                        setShowImportTaskModal={setShowImportTaskModal}
                      />
                    ) : activeAllTaskTab === "boardView" ? (
                      <AllTasksBoardView
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                      />
                    ) : activeAllTaskTab === "treeView" ? (
                      <AllTasksTreeView
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                        showAddMilestoneModal={showAddMilestoneModal}
                        setShowAddMilestoneModal={setShowAddMilestoneModal}
                        showImportTaskModal={showImportTaskModal}
                        setShowImportTaskModal={setShowImportTaskModal}
                      />
                    ) : (
                      <AllTasksGanttChartView
                        selectedProject={selectedProject}
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                        showImportTaskModal={showImportTaskModal}
                        setShowImportTaskModal={setShowImportTaskModal}
                      />
                    ))}
                  {currentTab === "stages" && (
                    <Stages selectedProject={selectedProject} />
                  )}
                  {currentTab === "dashboard" && (
                    <ProjectDashboard selectedProject={selectedProject} />
                  )}
                  {currentTab === "budget" && (
                    <PermissionGuard permissionCheck="PROJECT_BUDGET_VIEW">
                      <MonthWiseBudget
                        selectedProject={selectedProject}
                        getProject={getProject}
                      />
                    </PermissionGuard>
                  )}
                  {currentTab === "score" && (
                    <Score selectedProject={selectedProject} />
                  )}
                  {currentTab === "projectStatus" && (
                    <ProjectStatus selectedProject={selectedProject} />
                  )}
                  {currentTab === "members" && (
                    <Members
                      selectedProject={selectedProject}
                      showAddMemberModal={showAddMemberModal}
                      setShowAddMemberModal={setShowAddMemberModal}
                    />
                  )}
                  {currentTab === "milestones" && (
                    <Milestones
                      selectedProject={selectedProject}
                      showAddMilestoneModal={showAddMilestoneModal}
                      setShowAddMilestoneModal={setShowAddMilestoneModal}
                    />
                  )}
                  {currentTab === "issues" && (
                    <Issues
                      setShowAddIssueodal={setShowAddIssueodal}
                      showAddIssueodal={showAddIssueodal}
                      selectedProject={selectedProject}
                    />
                  )}
                  {/* {currentTab === "measure" && (
                    <Measure selectedProject={selectedProject} />
                  )} */}
                  {currentTab === "documents" && (
                    <Documents
                      selectedProject={selectedProject}
                      setShowAddPhysicalDoumentModal={
                        setShowAddPhysicalDoumentModal
                      }
                      showAddPhysicalDoumentModal={showAddPhysicalDoumentModal}
                      setShowAddDoumentLinkModal={setShowAddDoumentLinkModal}
                      showAddDoumentLinkModal={showAddDoumentLinkModal}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ProjectPreview;

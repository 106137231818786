import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getBudgetData } from "../api/budget/Budget";
import ProjectGantt from "../components/Home/ProjectGantt";
import useAuth from "../hooks/useAuth";
import useCommons from "../hooks/useCommons";
import useLoader from "../hooks/useLoader";
import usePortfolio from "../hooks/usePortfolio";
import useProject from "../hooks/useProject";
import ArrowRightIcon from "../icons/ArrowRight";
import HomePeople from "../icons/HomePeople.png";
import HomePortfolioIcon from "../icons/HomePortfolioIcon.png";
import HomeProjectIcon from "../icons/HomeProjectIcon.png";
import PlusIcon from "../icons/Plus";
import currencyFormatter from "../utils/currencyFormatter";
import Chart from "react-apexcharts";

const Home = () => {
  const { totalPortfolioCount } = usePortfolio();
  const { totalProjectCount } = useProject();
  const [totalBugetData, setTotalBugetData] = useState(null);
  const { allPeopleCount } = useCommons();
  const { loaderStart, loaderEnd } = useLoader();
  const { user } = useAuth();
  const chartData = {
    options: {
      chart: {
        id: "grouped-bar-chart",
        type: "bar",
        stacked: true,
      },
      xaxis: {
        categories: ["Oct 2023", "Nov 2023", "Dec 2023"],
      },
    },
    series: [
      {
        name: "Director",
        data: [10, 20, 15],
      },
      {
        name: "Consultant",
        data: [15, 25, 20],
      },
    ],
  };
  const getProjectBudget = async () => {
    try {
      loaderStart();
      const res = await getBudgetData({
        projectId: "",
        portfolioId: "",
        adminId: user.id,
      });
      if (res.data.success) {
        setTotalBugetData(res.data.data.budgetData[0]);
      }
      loaderEnd();
    } catch (error) {
      loaderEnd();
    }
  };

  useEffect(() => {
    getProjectBudget();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1} sx={{ p: 1 }}>
      <Grid item xs={12} md={8} sm={6}>
        <Grid container spacing={1} sx={{ background: "#fff", p: 1 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: "#fff",
                p: 2,
                boxShadow: "1px 1px 9px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: "3px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: "bold",
                    color: "#2D3E56",
                  }}
                >
                  PORTFOLIOS
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 3,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "32px",
                      lineHeight: "36px",
                      fontWeight: "700",
                      color: "#00000E",
                    }}
                  >
                    {totalPortfolioCount}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    component="img"
                    src={HomePortfolioIcon}
                    sx={{ width: "70px" }}
                  ></Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Button
                    size="small"
                    endIcon={<ArrowRightIcon fontSize="inherit" />}
                    sx={{ color: "#2D3E56" }}
                    component={RouterLink}
                    to="/dashboard/portfolios/view-all-portfolios"
                  >
                    View All
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="small"
                    startIcon={<PlusIcon fontSize="inherit" />}
                    sx={{ color: "#2D3E56" }}
                    component={RouterLink}
                    to="/dashboard/portfolios/add-new-portfolio"
                  >
                    Add New Portfolio
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                background: "#fff",
                p: 2,
                boxShadow: "1px 1px 9px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: "3px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: "bold",
                    color: "#2D3E56",
                  }}
                >
                  PROJECTS
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 3,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "32px",
                      lineHeight: "36px",
                      fontWeight: "700",
                      color: "#2984DF",
                    }}
                  >
                    {totalProjectCount}
                  </Typography>
                </Box>
                <Box sx={{ display: "inline-flex" }}>
                  <Box
                    component="img"
                    src={HomeProjectIcon}
                    sx={{
                      width: "70px",
                      marginTop: "-8px",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Button
                    size="small"
                    endIcon={<ArrowRightIcon fontSize="inherit" />}
                    sx={{ color: "#2D3E56" }}
                    component={RouterLink}
                    to="/dashboard/projects/view-all-projects"
                  >
                    View All
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="small"
                    startIcon={<PlusIcon fontSize="inherit" />}
                    sx={{ color: "#2D3E56" }}
                    component={RouterLink}
                    to="/dashboard/projects/add-new-project"
                  >
                    Add New Project
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            spacing={3}
            sx={{
              background: "#fff",
              boxShadow: "1px 1px 9px 1px rgba(0, 0, 0, 0.1)",
              mt: 1,
              p: 1,
            }}
          >
            <Box
              sx={{
                background: "#F8F9FB",
                p: 2,
                boxShadow: "1px 1px 9px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: "3px",
                mb: 2,
                // display: { xs: "none", sm: "block" },
              }}
            >
              <ProjectGantt />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#fff",
                p: 2,
                borderRadius: "3px",
                boxShadow: "1px 1px 9px 1px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    fontWeight: "bold",
                    color: "#2D3E56",
                  }}
                >
                  BUDGET
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  py: 2,
                  gap: 1,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      lineHeight: "23px",
                      fontWeight: "700",
                      color: "#333",
                    }}
                  >
                    Estimated
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      lineHeight: "30px",
                      fontWeight: "700",
                      color: "#00000E",
                    }}
                  >
                    ${" "}
                    {totalBugetData?.total_budget
                      ? currencyFormatter(totalBugetData?.total_budget)
                      : 0}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      lineHeight: "23px",
                      fontWeight: "700",
                      color: "#333",
                    }}
                  >
                    Actual
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "26px",
                      lineHeight: "30px",
                      fontWeight: "700",
                      color: "#9BD143",
                    }}
                  >
                    ${" "}
                    {totalBugetData?.total_actual
                      ? currencyFormatter(totalBugetData?.total_actual)
                      : 0}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Button
                    size="small"
                    endIcon={<ArrowRightIcon fontSize="inherit" />}
                    sx={{ color: "#2D3E56" }}
                    component={RouterLink}
                    to="/dashboard/budget"
                  >
                    View All
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
            <Box
              sx={{
                background: "#F8F9FB",
                p: 2,
                boxShadow: "1px 1px 9px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: "3px",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontWeight: "bold",
                        color: "#2D3E56",
                        minWidth: "130px",
                      }}
                    >
                      PEOPLE
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "32px",
                        lineHeight: "36px",
                        fontWeight: "700",
                        color: "#2984DF",
                      }}
                    >
                      {allPeopleCount}
                    </Typography>
                    <Button
                      size="small"
                      endIcon={<ArrowRightIcon fontSize="inherit" />}
                      sx={{ color: "#2D3E56" }}
                      component={RouterLink}
                      to="/dashboard/peoples/view-all"
                    >
                      View All
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ ml: "auto" }}>
                  <Box sx={{ display: "inline-flex" }}>
                    <Box
                      component="img"
                      src={HomePeople}
                      sx={{
                        width: "70px",
                        float: "right",
                      }}
                    ></Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                ></Box>
              </Box>
            </Box>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  Available Capacity By Role
                </Typography>
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height={350}
                />
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  Pagination,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import SearchIcon from "../../../icons/Search";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import SingleProjectInfo from "./SingleProjectInfo";
import useTable from "../../../hooks/useTable";
import useLoader from "../../../hooks/useLoader";

const ViewAllProjectListTable = (props) => {
  const { allProject, handleAllProject, getAllProject, ...other } = props;
  const { permissions } = useAuth();

  const headCells = [
    { id: "slNo", label: "#", disableSorting: true },
    { id: "name", label: "PROJECT NAME" },
    { id: "portfolio", label: "PORTFOLIO", disableSorting: true },
    { id: "priority", label: "PRIORITY" },
    { id: "start_date", label: "START DATE" },
    { id: "due_date", label: "END DATE" },
    { id: "status", label: "STATUS", disableSorting: true, align: "center" },
    { id: "budget", label: "BUDGET ($)" },
    { id: "spent_till_date", label: "ACTUAL ($)" },
  ];
  if (permissions["PROJECT_DELETE"]) {
    headCells.push({
      id: "action",
      label: "ACTION",
      disableSorting: true,
      align: "center",
    });
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [filterProjectType, setFilterProjectType] = useState(0);
  const [nameSearchValue, setNameSearchValue] = useState("");
  const [allProjectTypes, setAllProjectTypes] = useState([]);

  const { loaderState } = useLoader();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    allProject?.data,
    headCells
  );

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleFilterPortfolioTypeChange = (e) => {
    setCurrentPage(1);
    setFilterProjectType(e.target.value);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setNameSearchValue(e.target.value);
  };

  useEffect(() => {
    getAllProjectType();
  }, []);

  useEffect(() => {
    let portfolioType;
    if (filterProjectType === 0) {
      portfolioType = "";
    } else {
      portfolioType = filterProjectType;
    }
    handleAllProject(currentPage, portfolioType, nameSearchValue);
  }, [currentPage, filterProjectType, nameSearchValue]);

  //   const refreshUserList = () => {
  //     let portfolioType;
  //     if (portfolioType === 0) {
  //       portfolioType = "";
  //     } else {
  //       portfolioType = portfolioType;
  //     }
  //     handleAllProject(currentPage, filterProjectType, nameSearchValue);
  //   };

  //   const getAllPortfolioTypes = async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem("accessToken");

  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/newPfType`,
  //         {
  //           admin: user.id,
  //           pfType: "",
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       );

  //       setAllProjectTypes(response.data.pfTypes);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  const getAllProjectType = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/types`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setAllProjectTypes(response.data.data.projectTypes);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Card>
      {allProject?.data?.length > 0 ||
      filterProjectType !== 0 ||
      nameSearchValue !== "" ? (
        <>
          <Box
            sx={{
              m: -1,
              p: 3,
            }}
          >
            <Typography
              variant="dashboardContentHeader"
              sx={{
                color: "#2D3E56",
                mb: "18px",
                display: "block",
              }}
            >
              Project List
            </Typography>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="status"
                  onChange={handleFilterPortfolioTypeChange}
                  label=" Filter Projects"
                  select
                  SelectProps={{ native: true }}
                  value={filterProjectType || "all"}
                  variant="outlined"
                >
                  <option
                    aria-label="None"
                    value={0}
                    selected={filterProjectType === 0}
                  >
                    All
                  </option>
                  {allProjectTypes.map((projectType) => (
                    <option key={projectType.id} value={projectType.id}>
                      {projectType.type_name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={searchHandler}
                  label="Search projects"
                  value={nameSearchValue}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                m: 1,
                maxWidth: "100%",
                width: 240,
              }}
            ></Box>
          </Box>
          <Box sx={{ pt: 3 }}>
            {allProject?.data?.length > 0 && (
              <TblContainer>
                <TblHead />

                <TableBody>
                  {recordsAfterPagingAndSorting().map((project, index) => {
                    return (
                      <SingleProjectInfo
                        project={project}
                        key={index}
                        index={index}
                        getAllProject={getAllProject}
                        currentPage={currentPage}
                      />
                    );
                  })}
                </TableBody>
              </TblContainer>
            )}
            <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
              {allProject?.data?.length > 0 ? (
                <Pagination
                  page={currentPage}
                  onChange={handleChange}
                  count={allProject.last_page}
                />
              ) : (
                <Typography variant="h6" color="secondary">
                  {loaderState ? "Loading..." : "No Records Available"}
                </Typography>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" color="secondary">
            {loaderState ? "Loading..." : "No Records Available"}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default ViewAllProjectListTable;

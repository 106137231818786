import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  Grid,
  Link,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import ProjectStatus from "../../components/dashboard/report/ProjectStatus";
import ProjectHistory from "../../components/dashboard/report/ProjectHistory";
import ProjectHealth from "../../components/dashboard/report/ProjectHealth";
import useCommons from "../../hooks/useCommons";

const tabs = [
  { label: "Project Status", value: "project-status" },
  { label: "Health Report", value: "health-report" },
  { label: "Report History", value: "report-history" },
];

const ProjectReports = () => {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const activeTabParam = parameters.get("p");

  const { settings } = useSettings();
  const [currentTab, setCurrentTab] = useState(
    activeTabParam || "project-status"
  );
  const { allReportCount, updateCurrentPath } = useCommons();

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    window.history.pushState(null, null, `/dashboard/reports?p=${value}`);
    updateCurrentPath();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Reports</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Project Reports
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Reports
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              >
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="dashboardInfo"
                >
                  Total Reports:
                </Typography>
                <Typography
                  component="span"
                  variant="dashboardInfo"
                  sx={{
                    color: "#00000E",
                    pl: 1,
                  }}
                >
                  {allReportCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Card sx={{ p: 3, mt: 2 }}>
            <Box>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
                sx={{
                  ".MuiTab-wrapper": {
                    textTransform: "none",
                    fontSize: "12px",
                  },
                  ".Mui-selected": {
                    fontWeight: "bold",
                    color: "#000",
                  },
                  button: {
                    minWidth: "80px",
                    width: "14.27%",
                  },
                  ".MuiTabs-indicator": {
                    minWidth: "60px",
                    width: "14.27%",
                  },
                }}
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            {currentTab === "project-status" && <ProjectStatus />}
            {currentTab === "health-report" && <ProjectHealth />}
            {currentTab === "report-history" && <ProjectHistory />}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ProjectReports;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  TextField,
  Typography,
  Grid,
  Pagination,
} from "@material-ui/core";
import SearchIcon from "../../../icons/Search";
import SingleCredentialInfo from "./SingleCredentialInfo";
import roles from "../../../@const/roles";
import useTable from "../../../hooks/useTable";
import useAuth from "../../../hooks/useAuth";
import useLoader from "../../../hooks/useLoader";
import SingleTemplateInfo from "./SingleTemplateInfo";

const headCells = [
  { id: "slNo", label: "#" },
  { id: "label", label: "LABEL" },
  { id: "description", label: "DESCRIPTION" },
  { id: "action", label: "ACTION" },
];

const ViewAllProjectTemplates = (props) => {
  const { data, getAllTemplates, searchHandler, nameSearchValue } = props;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    data,
    headCells
  );
  return (
    <Card>
      <Box
        sx={{
          m: -1,
          p: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="dashboardContentHeader"
            sx={{
              color: "#2D3E56",
              mb: "18px",
              display: "inline",
            }}
          >
            All Templates
          </Typography>
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 240,
          }}
        >
          <TextField
            label="Search Template"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={searchHandler}
            placeholder="Enter template title"
            value={nameSearchValue}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <TblContainer>
          <TblHead />
          {data?.length > 0 && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((template, index) => {
                return (
                  <SingleTemplateInfo
                    template={template}
                    key={index}
                    index={index}
                    getAllTemplates={getAllTemplates}
                  />
                );
              })}
            </TableBody>
          )}
        </TblContainer>
      </Box>
    </Card>
  );
};

export default ViewAllProjectTemplates;

import { handleApiRequest } from "../../hooks/callApi";

export const getCalenderData = async ({ userId, startDate, endDate }) => {
  const res = await handleApiRequest(
    `/api/calendar/${userId}?start_date=${startDate}&end_date=${endDate}`,
    "get"
  );

  return res;
};

export const getAllUsersDailyUtilization = async (
  startDate,
  endDate,
  isWeekly,
  department,
  designation,
  memberNameFilter,
  projectFilter
) => {
  const res = await handleApiRequest(
    `/api/calendar/get-all-users-daily-utilization?start_date=${startDate}&end_date=${endDate}&isWeekly=${
      isWeekly ? 1 : 0
    }&department_id=${department}&designation=${designation}&user_name=${memberNameFilter}&project_id=${projectFilter}`,
    "get"
  );

  return res;
};

import { Box, Rating, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAltOutlined,
  SentimentVerySatisfied,
} from "@material-ui/icons";
const labels = {
  1: (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      Useless <SentimentVeryDissatisfied color="error" />
    </Typography>
  ),
  2: (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      Poor <SentimentDissatisfied color="error" />
    </Typography>
  ),
  3: (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      Ok <SentimentSatisfied color="primary" />
    </Typography>
  ),
  4: (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      Good <SentimentSatisfiedAltOutlined sx={{ color: "#4caf50" }} />
    </Typography>
  ),
  5: (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      Excellent <SentimentVerySatisfied sx={{ color: "#4caf50" }} />
    </Typography>
  ),
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
const StarRating = ({ readOnly, value, onChange, name }) => {
  const [hover, setHover] = React.useState(-1);
  const [hoverActive, setHoverActive] = React.useState(false);

  useEffect(() => {
    if (hover < 1) {
      setHoverActive(false);
    } else {
      setHoverActive(true);
    }
  }, [hover]);

  return (
    <Box
      sx={{
        width: 200,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name={name}
        readOnly={readOnly}
        value={value}
        getLabelText={getLabelText}
        onChange={(e) => onChange(e)}
        precision={1}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {hoverActive && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </Box>
  );
};

export default StarRating;

import React from "react";
import { Backdrop, CircularProgress, Button } from "@material-ui/core";
import useLoader from "../hooks/useLoader";
const Loader = () => {
  // loader
  const { loaderState } = useLoader();
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 100000000000,
          opacity: "0.1 !important",
        }}
        open={loaderState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Loader;

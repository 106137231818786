import React, { useState } from "react";
import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import { TableCell, TableRow } from "@material-ui/core";
import roles from "../../../@const/roles";
import EditPeopleModal from "./EditPeopleModal";
import { removePeople } from "../../../api/people/People";
import toast from "react-hot-toast";
import ViewAndEditPeopleModal from "./ViewAndEditPeopleModal";
import PermissionGuard from "../../PermissionGuard";
import useAuth from "../../../hooks/useAuth";
import DeleteModal from "../../DeleteModal";

const SingleMember = ({ member, getAllPeopleUnderAdmin, index }) => {
  const [showEditPeopleModal, setShowEditPeopleModal] = useState(false);
  const [showViewAndEditPeopleModal, setShowViewAndEditPeopleModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { permissions } = useAuth();

  const filterRole = (val) => {
    let obj = roles.find((o) => o.roleType === +val);
    return obj?.roleValue;
  };

  const deletePeople = async (peopleId) => {
    try {
      const res = await removePeople(peopleId);
      if (res.data.success) {
        toast.success("Member deleted");
        getAllPeopleUnderAdmin();
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onYesClick={() => deletePeople(member.id)}
        warningMessage="Are you sure you want to delete this member?"
      />
      {showViewAndEditPeopleModal && (
        <ViewAndEditPeopleModal
          showViewAndEditPeopleModal={showViewAndEditPeopleModal}
          setShowViewAndEditPeopleModal={setShowViewAndEditPeopleModal}
          getAllPeopleUnderAdmin={getAllPeopleUnderAdmin}
          member={member}
        />
      )}
      {showEditPeopleModal && (
        <EditPeopleModal
          showEditPeopleModal={showEditPeopleModal}
          setShowEditPeopleModal={setShowEditPeopleModal}
          getAllPeopleUnderAdmin={getAllPeopleUnderAdmin}
          member={member}
        />
      )}
      <TableRow hover>
        <TableCell
          onClick={() => setShowViewAndEditPeopleModal(true)}
          sx={{ cursor: "pointer" }}
        >{`${member?.first_name} ${member?.last_name}`}</TableCell>
        <TableCell>{member?.email || "-"}</TableCell>
        <TableCell>{member?.user_params?.designation || "-"}</TableCell>
        <TableCell>
          {member?.user_params?.get_department?.department || "-"}
        </TableCell>
        {(permissions["PEOPLE_UPDATE"] || permissions["PEOPLE_DELETE"]) && (
          <TableCell align="center">
            <PermissionGuard permissionCheck="PEOPLE_UPDATE" returnEmpty={true}>
              <PencilAltIcon
                title="Edit"
                onClick={() => setShowEditPeopleModal(true)}
                className="action-icon"
              />
            </PermissionGuard>
            <PermissionGuard permissionCheck="PEOPLE_DELETE" returnEmpty={true}>
              <TrashIcon
                title="delete"
                onClick={() => setShowDeleteModal(true)}
                className="action-icon"
              />
            </PermissionGuard>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default SingleMember;

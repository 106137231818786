import { useState, useEffect } from "react";
import {
  Box,
  Card,
  InputAdornment,
  TableBody,
  Pagination,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import SingleIntakeForm from "./SingleIntakeForm";
import useTable from "../../../hooks/useTable";

const RoomIntakeFormListTable = (props) => {
  const { allRooms, ...other } = props;
  const headCells = [
    { id: "name", label: "ROOM NAME" },
    { id: "status", label: "	STATUS" },
    { id: "contact_person", label: "CONTACT PERSON" },
    { id: "email", label: "	EMAIL", disableSorting: true },
    { id: "created_on", label: "	DATE CREATED", align: "right" },
    { id: "totalRoom", label: "TOTAL SEATS", align: "right" },
    { id: "occupiedRooms", label: "OCCUPIED SEATS", align: "right" },
    {id: "action", label: "ACTION", disableSorting: true, align: "center"},
  ];
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    allRooms,
    headCells
  );
  return (
    <Card {...other}>
      <Box
        sx={{
          m: -1,
          p: 3,
        }}
      >
        <Typography
          variant="dashboardContentHeader"
          sx={{
            color: "#2D3E56",
            mb: "18px",
            display: "block",
          }}
        >
          Rooms List
        </Typography>
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 240,
          }}
        ></Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <TblContainer>
          <TblHead />
          {allRooms?.length > 0 && (
            <TableBody>
              {recordsAfterPagingAndSorting().map((room, index) => {
                return (
                  <SingleIntakeForm
                    room={room}
                    key={index}
                    index={index}
                    {...other}
                  />
                );
              })}
            </TableBody>
          )}
        </TblContainer>
      </Box>
    </Card>
  )
}

export default RoomIntakeFormListTable
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export const callAxios = async (url, method, body = null, headers = {}) => {
  const accessToken = window.localStorage.getItem("accessToken") || null;
  const header = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    },
  };
  if (method === "get" || method === "delete") {
    const res = axios[method](url, header);
    return res;
  }
  const res = axios[method](url, body, header);
  return res;
};

export const handleApiRequest = async (url, method, body = null, headers) => {
  try {
    const res = await callAxios(url, method, body, headers);

    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      console.log("err", error.response);
      // window.location.replace(
      //   `${process.env.REACT_APP_FRONTEND_URL}/un-authenticated`
      // );
      return;
    }
    console.log("err", error.response);
    // toast.error(`Something went wrong`);
  }
};
